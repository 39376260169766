.info-key {
    width: 24%;
    margin-right: 3px !important;
    min-width: 175px;
    max-width: 200px;
    font-weight: 500;
}

.info-value {
    width: -webkit-fill-available !important;
    min-width: 320px;

    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}