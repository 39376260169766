.searchBarParent {
    margin: 0;
    padding: 0;
    color: #000000;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.searchBarItemTitle {
    padding: 8px 8px;
    font-weight: normal;
    border-right: 1px solid #d9d9d9;
    font-size: 15px;
    text-align: center;
    background: #fafafa;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 0.3s;
}