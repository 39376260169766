.app-switcher-popover {
  position: fixed;
}

.app-switcher-popover .ant-popover-arrow {
  display: none;
}

.layout-header-app-switcher {
  position: fixed;
  top: 0px;
  right: 24px;
}

.app-switcher-popover .ant-popover-inner-content {
  padding: 0px;
}

.app-switcher-popover.ant-popover {
  line-height: 0;
}

.layout-header-app-switcher-btn:hover {
  background: rgba(0, 0, 0, 0.1);
}

.mask {
-webkit-mask-image: linear-gradient(to top, transparent -1%, white 10%);
mask-image: linear-gradient(to top, transparent -1%, white 10%);
}

@media (max-width: 576px) and (orientation: portrait) {
  .app-switcher {
    width: 100vw;
    height: calc(100vh - 64px);
  }
}

@media (min-width: 577px) and (orientation: portrait) {
  .app-switcher {
    width: 320px;
    height: 370px;
  }
}

@media (max-height: 576px) and (orientation: landscape) {
  .app-switcher {
    width: 320px;
    height: calc(100vh - 64px);
  }
}

@media (min-height: 577px) and (orientation: landscape) {
  .app-switcher {
    width: 320px;
    height: 370px;
  }
}